/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages


                $(document).ready(function() {
                    $('#slider').flexslider({
                        animation: "slide",
                        start: function() {
                            $('.flexImages').show();
                        },

                    });
                });




                $('.mobile-menu-toggle').click(function(e) {
                    $('body').toggleClass('enable-mobile-menu');
                    $('body').removeClass('scroll-mobile-menu');
                });
                $('.mobile-menu').scroll(function(e) {
                    $('body').addClass('scroll-mobile-menu');
                });

                $(function() {
                    $('.loupe_btn').on('click', function(event) {
                        event.preventDefault();
                        $('#overlay').addClass('open');
                        $('#overlay').removeClass('close');
                        $('#overlay > form > input[type="search"]').focus();
                    });
                    $('.close').on('click keyup', function(event) {

                        $('#overlay').removeClass('open');
                        $('#overlay').addClass('close');
                    });
                });

                // Add some classes to body for CSS hooks
                // Get browser
                $.each($.browser, function(i) {
                    $('body').addClass(i);
                    return false;
                });
                // Get OS
                var os = [
                    'iphone',
                    'ipad',
                    'windows',
                    'mac',
                    'linux',
                    'android',
                    'mobile'
                ];
                var match = navigator.appVersion.toLowerCase().match(new RegExp(os.join('|')));
                if (match) {
                    $('body').addClass(match[0]);
                }
                if (typeof match[0] === 'undefined') {
                    match[0] = '';
                }
                if ((navigator.appVersion.indexOf("Win") !== -1 || navigator.appVersion.indexOf("Mac") !== -1 || navigator.appVersion.indexOf("X11") !== -1 || match[0] === 'windows' || match[0] === 'mac') && match[0] !== 'iphone' && match[0] !== 'ipad') {
                    $('body').addClass('pc');
                } else {
                    $('body').addClass('mobile');
                }
                //End body class
                $('#slider-hp').flexslider({
                    animation: "slide",

                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS



            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.